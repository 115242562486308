<template>
	<el-form class="page" ref="form" :label-position="'top'">
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
						<el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
					</el-tooltip>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item :label="$t('redVoznje.linija')">
					<select-linije class="full" @change="promenaLinije" :disabled="id && id !== 'create' ? true : false" v-model="redVoznje.id_linije" ref="linija"/>
				</el-form-item>
				<el-form-item v-if="mesta.length">
					<h2>{{$t('redVoznje.polazak')}}</h2>
					<el-table
							fit
							:data="mesta"
							v-loading="gridLoad"
							stripe
							border
							style="width: 100%">
						<el-table-column
								min-width="150px"
								align="center">
							<template slot-scope="scope">
								<span>{{scope.row.naziv}}</span>
							</template>
						</el-table-column>
						<el-table-column
								min-width="150px"
								align="center"
								:label="item.name"
								:key="item.id"
								v-for="item in tableHead">
							<template slot-scope="scope">
								<el-row>
									<el-col :span="12">
										<el-checkbox border v-model="scope.row.polazak[item.id].check" class="checkbox-in-grid"/>
									</el-col>
									<el-col :span="12">
										<el-popover
												placement="top"
												width="200">
											<el-row>
												<el-col :span="24">
													<el-time-select class="width-full-important"
																	v-model="scope.row.polazak[item.id].vreme"
																	:picker-options="{start: '00:00', end: '23:59', step: '00:01'}"/>
												</el-col>
												<el-col :span="24" class="p--t-10">
													<el-input type="textarea"
																:autosize="{ minRows: 3, maxRows: 4}"
																v-model="scope.row.polazak[item.id].stanica"/>
												</el-col>
											</el-row>
											<el-button icon="fas fa-bus" slot="reference"/>
										</el-popover>
									</el-col>
								</el-row>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
				<el-form-item v-if="mesta.length">
					<h2>{{$t('redVoznje.povratak')}}</h2>
					<el-table
							:data="mesta"
							v-loading="gridLoad"
							border
							stripe
							:default-sort="{prop: 'pozicija', order: 'descending'}"
							style="width: 100%">
						<el-table-column
								prop="pozicija"
								min-width="150px"
								align="center">
							<template slot-scope="scope">
								<span>{{scope.row.naziv}}</span>
							</template>
						</el-table-column>
						<el-table-column
								min-width="150px"
								align="center"
								:label="item.name"
								:key="item.id"
								v-for="item in tableHead">
							<template slot-scope="scope">
								<el-row>
									<el-col :span="12">
										<el-checkbox border v-model="scope.row.povratak[item.id].check" class="checkbox-in-grid"/>
									</el-col>
									<el-col :span="12">
										<el-popover
												placement="top"
												width="200">
											<el-row>
												<el-col :span="24">
													<el-time-select class="width-full-important"
																	v-model="scope.row.povratak[item.id].vreme"
																	:picker-options="{start: '00:00', end: '23:59', step: '00:01'}"/>
												</el-col>
												<el-col :span="24" class="p--t-10">
													<el-input type="textarea"
																:autosize="{ minRows: 3, maxRows: 4}"
																v-model="scope.row.povratak[item.id].stanica"/>
												</el-col>
											</el-row>
											<el-button icon="fas fa-bus" slot="reference"/>
										</el-popover>
									</el-col>
								</el-row>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-button type="success" :disabled="!redVoznje.id_linije || onSubmitLoading" v-loading="onSubmitLoading" @click="onSubmit">{{ $t('global.sacuvaj') }}</el-button>
					<el-button type="info" @click="$utils('goBack')">{{ $t('global.odustani') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
export default {
	name: 'red-voznje-edit',
	data() {
		return {
			fullscreenLoading: true,
			onSubmitLoading: false,
			id: '',
			formCreate: '',
			linijeMesta: [],
			mesta: [],
			gridLoad: false,
			tableHead: [
				{ id: 1, name: this.$t('global.dani[1]') },
				{ id: 2, name: this.$t('global.dani[2]') },
				{ id: 3, name: this.$t('global.dani[3]') },
				{ id: 4, name: this.$t('global.dani[4]') },
				{ id: 5, name: this.$t('global.dani[5]') },
				{ id: 6, name: this.$t('global.dani[6]') },
				{ id: 7, name: this.$t('global.dani[7]') }
			],
			tabela: [],
			redVoznje: []
		};
	},
	mounted() {
		this.id = this.$route.params.id;
		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('redVoznje.redVoznje')
		});

		this.$utils('stopLoadingAfter', [this.getRedVoznje()]);
	},
	methods: {
		getRedVoznje() {
			if (!this.formCreate) {
				return this.$get('redVoznje', { id: this.id }).then(data => {
					this.redVoznje = data;
					return this.getLinije(data.id_linije);
				});
			}
		},
		promenaLinije(id) {
			this.$store.commit('setLoading', true);
			this.$utils('stopLoadingAfter', [this.getLinije(id)]);
		},
		getLinije(id) {
			if (!id)
				return;

			return this.$get('linije', { id: id }).then(data => {
				this.linijeMesta = data.mesta;
				this.postaviPodatke();
			});
		},
		onSubmit() {
			this.onSubmitLoading = true;
			let redVoznjePodaci = [];
			this.mesta.forEach(value => {
				this.tableHead.forEach(dani => {
					redVoznjePodaci.push({
						id_mesta: value.id_mesta,
						dan_polazak: dani.id,
						polazak_check: value.polazak[dani.id].check === true ? 1 : 0,
						polazak_stanica: {
							vreme: value.polazak[dani.id].vreme,
							stanica: value.polazak[dani.id].stanica
						},
						dan_povratak: dani.id,
						povratak_check: value.povratak[dani.id].check === true ? 1 : 0,
						povratak_stanica: {
							vreme: value.povratak[dani.id].vreme,
							stanica: value.povratak[dani.id].stanica
						}
					});
				});
			});

			let dataNew = {
				aktivno: this.redVoznje.aktivno || 'NE',
				id_linije: this.redVoznje.id_linije,
				red_voznje: redVoznjePodaci
			};

			let data = JSON.stringify(dataNew);

			if (this.formCreate)
				this.$save('redVoznje', data);
			else
				this.$update('redVoznje', { id: this.id }, data);
		},
		postaviPodatke() {
			function nadjiRed(obj, mesto, dan, vratiSta = false, danPar = false) {
				if (obj === null)
					return false;

				let nadjen = false;
				if (danPar === 'dan_povratak')
					nadjen = _.find(obj, { id_mesta: mesto, dan_povratak: dan });
				if (danPar === 'dan_polazak')
					nadjen = _.find(obj, { id_mesta: mesto, dan_polazak: dan });
				if (vratiSta)
					return nadjen[vratiSta];
				return nadjen;
			}


			this.gridLoad = true;
			this.mesta = [];

			let redVoznje = (this.redVoznje.red_voznje && this.redVoznje.red_voznje.length > 0) ? _.cloneDeep(this.redVoznje.red_voznje) : null;

			this.linijeMesta.forEach(val => {
				this.mesta.push({
					naziv: val.naziv_izvorno,
					id_mesta: val.id_mesta,
					pozicija: val.pozicija,
					polazak: {
						1: {
							check: nadjiRed(redVoznje, val.id_mesta, 1, 'polazak_check', 'dan_polazak') === 1,
							vreme: nadjiRed(redVoznje, val.id_mesta, 1, 'polazak_stanica', 'dan_polazak').vreme || val.vreme_polaska,
							stanica: nadjiRed(redVoznje, val.id_mesta, 1, 'polazak_stanica', 'dan_polazak').stanica || val.stanica_polaska
						},
						2: {
							check: nadjiRed(redVoznje, val.id_mesta, 2, 'polazak_check', 'dan_polazak') === 1,
							vreme: nadjiRed(redVoznje, val.id_mesta, 2, 'polazak_stanica', 'dan_polazak').vreme || val.vreme_polaska,
							stanica: nadjiRed(redVoznje, val.id_mesta, 2, 'polazak_stanica', 'dan_polazak').stanica || val.stanica_polaska
						},
						3: {
							check: nadjiRed(redVoznje, val.id_mesta, 3, 'polazak_check', 'dan_polazak') === 1,
							vreme: nadjiRed(redVoznje, val.id_mesta, 3, 'polazak_stanica', 'dan_polazak').vreme || val.vreme_polaska,
							stanica: nadjiRed(redVoznje, val.id_mesta, 3, 'polazak_stanica', 'dan_polazak').stanica || val.stanica_polaska
						},
						4: {
							check: nadjiRed(redVoznje, val.id_mesta, 4, 'polazak_check', 'dan_polazak') === 1,
							vreme: nadjiRed(redVoznje, val.id_mesta, 4, 'polazak_stanica', 'dan_polazak').vreme || val.vreme_polaska,
							stanica: nadjiRed(redVoznje, val.id_mesta, 4, 'polazak_stanica', 'dan_polazak').stanica || val.stanica_polaska
						},
						5: {
							check: nadjiRed(redVoznje, val.id_mesta, 5, 'polazak_check', 'dan_polazak') === 1,
							vreme: nadjiRed(redVoznje, val.id_mesta, 5, 'polazak_stanica', 'dan_polazak').vreme || val.vreme_polaska,
							stanica: nadjiRed(redVoznje, val.id_mesta, 5, 'polazak_stanica', 'dan_polazak').stanica || val.stanica_polaska
						},
						6: {
							check: nadjiRed(redVoznje, val.id_mesta, 6, 'polazak_check', 'dan_polazak') === 1,
							vreme: nadjiRed(redVoznje, val.id_mesta, 6, 'polazak_stanica', 'dan_polazak').vreme || val.vreme_polaska,
							stanica: nadjiRed(redVoznje, val.id_mesta, 6, 'polazak_stanica', 'dan_polazak').stanica || val.stanica_polaska
						},
						7: {
							check: nadjiRed(redVoznje, val.id_mesta, 7, 'polazak_check', 'dan_polazak') === 1,
							vreme: nadjiRed(redVoznje, val.id_mesta, 7, 'polazak_stanica', 'dan_polazak').vreme || val.vreme_polaska,
							stanica: nadjiRed(redVoznje, val.id_mesta, 7, 'polazak_stanica', 'dan_polazak').stanica || val.stanica_polaska
						}
					},
					povratak: {
						1: {
							check: nadjiRed(redVoznje, val.id_mesta, 1, 'povratak_check', 'dan_povratak') === 1,
							vreme: nadjiRed(redVoznje, val.id_mesta, 1, 'povratak_stanica', 'dan_povratak').vreme || val.vreme_dolaska,
							stanica: nadjiRed(redVoznje, val.id_mesta, 1, 'povratak_stanica', 'dan_povratak').stanica || val.stanica_polaska
						},
						2: {
							check: nadjiRed(redVoznje, val.id_mesta, 2, 'povratak_check', 'dan_povratak') === 1,
							vreme: nadjiRed(redVoznje, val.id_mesta, 2, 'povratak_stanica', 'dan_povratak').vreme || val.vreme_dolaska,
							stanica: nadjiRed(redVoznje, val.id_mesta, 2, 'povratak_stanica', 'dan_povratak').stanica || val.stanica_polaska
						},
						3: {
							check: nadjiRed(redVoznje, val.id_mesta, 3, 'povratak_check', 'dan_povratak') === 1,
							vreme: nadjiRed(redVoznje, val.id_mesta, 3, 'povratak_stanica', 'dan_povratak').vreme || val.vreme_dolaska,
							stanica: nadjiRed(redVoznje, val.id_mesta, 3, 'povratak_stanica', 'dan_povratak').stanica || val.stanica_polaska
						},
						4: {
							check: nadjiRed(redVoznje, val.id_mesta, 4, 'povratak_check', 'dan_povratak') === 1,
							vreme: nadjiRed(redVoznje, val.id_mesta, 4, 'povratak_stanica', 'dan_povratak').vreme || val.vreme_dolaska,
							stanica: nadjiRed(redVoznje, val.id_mesta, 4, 'povratak_stanica', 'dan_povratak').stanica || val.stanica_polaska
						},
						5: {
							check: nadjiRed(redVoznje, val.id_mesta, 5, 'povratak_check', 'dan_povratak') === 1,
							vreme: nadjiRed(redVoznje, val.id_mesta, 5, 'povratak_stanica', 'dan_povratak').vreme || val.vreme_dolaska,
							stanica: nadjiRed(redVoznje, val.id_mesta, 5, 'povratak_stanica', 'dan_povratak').stanica || val.stanica_polaska
						},
						6: {
							check: nadjiRed(redVoznje, val.id_mesta, 6, 'povratak_check', 'dan_povratak') === 1,
							vreme: nadjiRed(redVoznje, val.id_mesta, 6, 'povratak_stanica', 'dan_povratak').vreme || val.vreme_dolaska,
							stanica: nadjiRed(redVoznje, val.id_mesta, 6, 'povratak_stanica', 'dan_povratak').stanica || val.stanica_polaska
						},
						7: {
							check: nadjiRed(redVoznje, val.id_mesta, 7, 'povratak_check', 'dan_povratak') === 1,
							vreme: nadjiRed(redVoznje, val.id_mesta, 7, 'povratak_stanica', 'dan_povratak').vreme || val.vreme_dolaska,
							stanica: nadjiRed(redVoznje, val.id_mesta, 7, 'povratak_stanica', 'dan_povratak').stanica || val.stanica_polaska
						}
					}
				});
			});
			this.gridLoad = false;
		}
	},
	components: {
		selectLinije: require('../../components/select/linije').default
	}
};
</script>
